<template>
  <div>
    <div><router-link to="/general/likeNew" class="btn btn-success">Создать</router-link></div>
  <div class="auto-dealer-table mt-10">
    <v-data-table
        :headers="headers"
        :items="filterItems"
        disable-pagination
        item-key="name"
        class="elevation-1 row-links"
        hide-default-footer
        :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
    >
      <template #item.active="{ item }">
        <div v-if="item?.active" class="label label-lg label-inline label-light-success">Да</div>
        <div v-else class="label label-lg label-inline label-light-danger">Нет</div>
      </template>
      <template #item.pct="{ item }">
        <img v-if="item?.pct" :src="item.pct" class="likePct">
      </template>
      <template #item.edit="{ item }">
        <span @click="getDetail(item.id)">Подробности</span>
        <span
            title="Редактировать"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary" @click="editLike(item.id)">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </span>
      </template>
      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <div v-if="shownAdd">
      <likes-add @onAdded="onAdded" :forAddProp="forAdd" />
    </div>

  </div>
  </div>
</template>

<script>
import { GET_ITEMS_LIKES, GET_LIKE } from '@/core/services/store/likes.module';
import likesAdd from './LikeAdd';
import {
  SET_BREADCRUMB,
} from '@/core/services/store/breadcrumbs.module';

export default {
  name: 'Likes',
  components: { likesAdd },
  beforeMount() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Реакции' },
    ]);
  },
  mounted() {
    this.getLikes();
  },
  data() {
    return {
      likesList: [],
      headers: [
        {text: 'Активность', value: 'active', sortable: true},
        {text: '', value: 'pct', sortable: true},
        {text: 'Название', value: 'name', sortable: true},
        {text: 'Code', value: 'code', sortable: true},
        {text: '', value: 'edit', sortable: false},
      ],
      shownAdd: false,
      forAdd: {
        id:false,
        name:'',
        code:'',
        pct:'',
        active:false,
      },
    }
  },
  computed: {
    filterItems() {
      let result = this.likesList?.map(function(i) {
        return {
          'id': i.ID,
          'name': i.Name,
          'pct': i.ImageUrl,
          'active': i.IsActive,
          'code': i.Code,
        };
      });
      return result;
    },
  },
  methods: {
    async getLikes() {
      let res = await this.$store.dispatch(GET_ITEMS_LIKES);
      this.likesList = res?.data.data;
    },
    async getDetail(id) {
      if(id) {
        let res = await this.$store.dispatch(GET_LIKE, id);
        console.log(res);
      }
    },
    editLike(id) {
      if(id) {
        let l = this.filterItems.find(x => x.id == id);
        if(l) {
          this.forAdd = {
            name:l.name,
            code:l.code,
            pct:l.pct,
            active:l.active,
          };
        }
        this.shownAdd = true;
        this.forAdd.id = id;
      }
    },
    showAdd() {
      this.shownAdd = !this.shownAdd;
      this.forAdd.id = {
        id:false,
        name:'',
        code:'',
        pct:'',
        active:false,
      };
    },
    onAdded() {
      this.getLikes();
      this.showAdd();
    }
  },
};
</script>
<style>
.likePct{
  width: 50px;
}
</style>